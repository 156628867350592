import { DAY_IN_MS } from '../../constants';
import { getOmnisendContext } from '../../context';

const sessionCookies: { [key: string]: string } = {};

export function setCookie(name: string, value: string, { expiration = DAY_IN_MS * 365, sessionOnly = false } = {}): void {
    // Inner workings of omnisend.cookies, expiration must be undefined to be stored in a session cookie
    // @ts-expect-error null-check
    getOmnisendContext().cookies?.set(name, value, sessionOnly ? undefined : expiration);

    sessionCookies[name] = value;
}

export function getCookie(name: string): string {
    return getOmnisendContext().cookies?.get(name) ?? sessionCookies?.[name];
}
