import { getFormBlockFieldInputSelector, getFormFieldSelectSelector, getPhoneNumberPrefixSelector } from '../elements';
import { trackInteraction } from '../tracking';
import { Form } from '../types';

const attachFocusListeners = (elements: Element[], listener: () => unknown): void => {
    const focusListener = (): void => {
        listener();
    };
    elements.forEach((el) => {
        el.addEventListener('focus', focusListener);
    });
};

export const attachFieldsFocusListeners = (formElement: HTMLElement, form: Form): void => {
    const { id, fields } = form.data;

    // @ts-expect-error null-check
    const fieldElements = fields
        .map((field) => {
            const inputField = formElement.querySelector(getFormBlockFieldInputSelector(id, field.targetID, field.name));
            const selectField = formElement.querySelector(getFormFieldSelectSelector(id, field.targetID, field.name));
            const phoneNumberPrefixField = formElement.querySelector(getPhoneNumberPrefixSelector(id, field.targetID));
            return inputField || selectField || phoneNumberPrefixField;
        })
        .filter((fieldEl) => !!fieldEl);

    if (!fieldElements.length) {
        return;
    }

    // @ts-expect-error null-check
    attachFocusListeners(fieldElements, async () => {
        await trackInteraction(form);
    });
};
