import { FieldErrorType } from '../types';
import {
    getFormBlockErrorSelector,
    getFormBlockInputSelector,
    getFormBlockLabelSelector,
    getFormBlockRequiredErrorSelector,
    getFormContainerSelector,
    getFormContentSelector,
    getFormSubmitErrorSelector,
    getFormFieldCheckboxSelector,
    getFormFieldRadioSelector,
    getFormFieldSelectSelector,
    getFormTeaserButtonSelector,
    getPhoneNumberPrefixSelector,
    getPromotionalFormSelector,
    getSubmitFormSelector,
    getFormNextStepErrorSelector
} from './selectors';

export * from './selectors';

type ElementSelectorOptions = {
    parentElement: HTMLElement;
    formID: string;
    stepID?: string;
};

type FormFieldElementSelectorOptions = {
    fieldID: string;
    fieldName?: string;
} & ElementSelectorOptions;

export const getFormSubmitErrorElement = ({ parentElement, formID }: ElementSelectorOptions): HTMLElement => {
    // @ts-expect-error null-check
    return parentElement.querySelector(getFormSubmitErrorSelector(formID));
};

export const getFormNextStepErrorElement = ({ parentElement, formID }: ElementSelectorOptions): HTMLElement => {
    // @ts-expect-error null-check
    return parentElement.querySelector(getFormNextStepErrorSelector(formID));
};

export const getFormFieldVisualElements = ({
    parentElement,
    formID,
    fieldID,
    fieldName
}: FormFieldElementSelectorOptions): HTMLElement[] => {
    let fieldInputElements: HTMLElement[] = [];
    let fieldSelectElements: HTMLElement[] = [];
    let fieldRadioElements: HTMLElement[] = [];

    parentElement.querySelectorAll<HTMLElement>(getFormBlockInputSelector(formID, fieldID)).forEach((el) => {
        fieldInputElements = [...fieldInputElements, el];
    });
    // @ts-expect-error null-check
    parentElement.querySelectorAll<HTMLElement>(getFormFieldSelectSelector(formID, fieldID, fieldName)).forEach((el) => {
        fieldSelectElements = [...fieldSelectElements, el];
    });
    // @ts-expect-error null-check
    parentElement.querySelectorAll<HTMLElement>(getFormFieldRadioSelector(formID, fieldID, fieldName)).forEach((el) => {
        fieldRadioElements = [...fieldRadioElements, el];
    });

    // @ts-expect-error null-check
    return [
        ...fieldInputElements,
        ...fieldSelectElements,
        ...fieldRadioElements,
        parentElement.querySelector<HTMLElement>(getPhoneNumberPrefixSelector(formID, fieldID)),
        parentElement.querySelector<HTMLElement>(getFormBlockLabelSelector(formID, fieldID))
    ].filter((element) => !!element);
};

export const getFormFieldElement = ({ parentElement, formID, fieldID, fieldName }: FormFieldElementSelectorOptions): HTMLElement =>
    // @ts-expect-error null-check
    parentElement.querySelector(getFormFieldCheckboxSelector(formID, fieldID)) ||
    parentElement.querySelector(getFormBlockInputSelector(formID, fieldID)) ||
    // @ts-expect-error null-check
    parentElement.querySelector(getFormFieldSelectSelector(formID, fieldID, fieldName)) ||
    // @ts-expect-error null-check
    parentElement.querySelector(getFormFieldRadioSelector(formID, fieldID, fieldName));

export const getFormFieldErrorElement = ({ parentElement, formID, fieldID }: FormFieldElementSelectorOptions): HTMLElement => {
    // @ts-expect-error null-check
    return parentElement.querySelector<HTMLElement>(getFormBlockErrorSelector(formID, fieldID));
};

export const getFormFieldRequiredErrorElement = ({ parentElement, formID, fieldID }: FormFieldElementSelectorOptions): HTMLElement => {
    // @ts-expect-error null-check
    return parentElement.querySelector<HTMLElement>(getFormBlockRequiredErrorSelector(formID, fieldID));
};

export const getFormFieldErrorElementByErrorType = (
    { parentElement, formID, fieldID }: FormFieldElementSelectorOptions,
    fieldErrorType: FieldErrorType
): HTMLElement => {
    if (fieldErrorType !== FieldErrorType.fieldInvalid && fieldErrorType !== FieldErrorType.fieldMissing) {
        // @ts-expect-error null-check
        return;
    }

    const isInvalidFieldErrorType = fieldErrorType === FieldErrorType.fieldInvalid;

    return isInvalidFieldErrorType
        ? getFormFieldErrorElement({ parentElement, formID, fieldID })
        : getFormFieldRequiredErrorElement({ parentElement, formID, fieldID });
};

const FOCUSABLE_ELEMENTS = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

export const getFocusableElements = (parentElement: HTMLElement): HTMLElement[] => {
    const focusableElements = parentElement?.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS);
    return focusableElements ? Array.from(focusableElements) : [];
};

export const getFormStepElement = ({ parentElement, formID, stepID }: ElementSelectorOptions): HTMLElement => {
    // @ts-expect-error null-check
    return (
        // @ts-expect-error null-check
        parentElement.querySelector<HTMLElement>(getSubmitFormSelector(formID, stepID)) ||
        // @ts-expect-error null-check
        parentElement.querySelector<HTMLElement>(getPromotionalFormSelector(formID, stepID))
    );
};

export const getFormTeaserButtonElement = ({ parentElement, formID }: ElementSelectorOptions): HTMLElement => {
    // @ts-expect-error null-check
    return parentElement.querySelector<HTMLElement>(getFormTeaserButtonSelector(formID));
};

export const getFormContainerElement = ({ parentElement, formID }: ElementSelectorOptions): HTMLElement => {
    // @ts-expect-error null-check
    return parentElement.querySelector<HTMLElement>(getFormContainerSelector(formID));
};

export const getFormContentElement = ({ parentElement, formID }: ElementSelectorOptions): HTMLElement => {
    // @ts-expect-error null-check
    return parentElement.querySelector<HTMLElement>(getFormContentSelector(formID));
};
