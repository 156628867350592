import { isVisibleElement } from '../../utils';
import { KEYBOARD_KEYS, NAVIGATION_KEYS, PAGE_SIZE, SELECT_CLASSNAMES } from './constants';
import { hideOptionsContainer, markOptionVisuallySelected, selectOption, showOptionsContainer } from './dom-actions';
import { SelectMetadata } from './types';

const LETTER_KEY_REGEXP = /^[a-zA-Z]$/;

const clearCurrentSelection = (options: NodeListOf<Element>): void => {
    options.forEach((option) => {
        option?.classList?.remove(SELECT_CLASSNAMES.visuallyFocusedOption);
    });
};

const scrollToOption = (options: NodeListOf<Element>, index: number, metadata: SelectMetadata): void => {
    const option = options[index];

    clearCurrentSelection(options);
    markOptionVisuallySelected(option, metadata);
    option?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
};

const getVisualFocusIndex = (key: string, visualFocusIndex: number, lastIndex: number): number => {
    switch (key) {
        case KEYBOARD_KEYS.END:
            visualFocusIndex = lastIndex;
            break;
        case KEYBOARD_KEYS.HOME:
            visualFocusIndex = 0;
            break;
        case KEYBOARD_KEYS.PAGE_DOWN:
            visualFocusIndex = visualFocusIndex + PAGE_SIZE > lastIndex ? lastIndex : visualFocusIndex + PAGE_SIZE;
            break;
        case KEYBOARD_KEYS.PAGE_UP:
            visualFocusIndex = visualFocusIndex - PAGE_SIZE < 0 ? 0 : visualFocusIndex - PAGE_SIZE;
            break;
        case KEYBOARD_KEYS.ARROW_DOWN:
            visualFocusIndex = visualFocusIndex === lastIndex ? visualFocusIndex : visualFocusIndex + 1;
            break;
        case KEYBOARD_KEYS.ARROW_UP:
            visualFocusIndex = visualFocusIndex === 0 ? visualFocusIndex : visualFocusIndex - 1;
            break;
    }

    return visualFocusIndex;
};

const getVisuallySelectedOptionIndex = (options: NodeListOf<Element>): number | undefined => {
    let visuallySelectedOptionIndex;

    options.forEach((option, index) => {
        if (option.classList.contains(SELECT_CLASSNAMES.visuallyFocusedOption)) {
            visuallySelectedOptionIndex = index;
        }
    });

    return visuallySelectedOptionIndex;
};

export const selectKeydownListener = (metadata: SelectMetadata): ((event: KeyboardEvent) => void) => {
    const { selectors, formElement } = metadata;
    const optionsContainer = formElement.querySelector(selectors.optionsContainer);
    const options = formElement.querySelectorAll(selectors.option);

    let visualFocusIndex = 0;

    const getSearchResultIndex = makeSearchHandler(options);

    return (event: KeyboardEvent): void => {
        const { key, altKey } = event;

        const visuallySelectedOptionIndex = getVisuallySelectedOptionIndex(options);
        visualFocusIndex = visuallySelectedOptionIndex !== undefined ? visuallySelectedOptionIndex : visualFocusIndex;

        // @ts-expect-error null-check
        if (!isVisibleElement(optionsContainer)) {
            if ([KEYBOARD_KEYS.PAGE_DOWN, KEYBOARD_KEYS.PAGE_UP, KEYBOARD_KEYS.TAB].includes(key)) {
                return;
            }

            if ([KEYBOARD_KEYS.ARROW_DOWN, KEYBOARD_KEYS.ARROW_UP, KEYBOARD_KEYS.ENTER, KEYBOARD_KEYS.SPACE].includes(key)) {
                event.preventDefault();
                scrollToOption(options, visualFocusIndex, metadata);
                showOptionsContainer(metadata);
                return;
            }
        }

        // @ts-expect-error null-check
        if (isVisibleElement(optionsContainer)) {
            if (key === KEYBOARD_KEYS.TAB) {
                selectOption(options[visualFocusIndex] as HTMLElement, metadata, options);
            }

            // @ts-expect-error null-check
            if (key === KEYBOARD_KEYS.ESCAPE && isVisibleElement(optionsContainer)) {
                event.stopPropagation();
                hideOptionsContainer(metadata);
                return;
            }

            if ((key === KEYBOARD_KEYS.ARROW_UP && altKey) || [KEYBOARD_KEYS.ENTER, KEYBOARD_KEYS.SPACE].includes(key)) {
                event.preventDefault();
                selectOption(options[visualFocusIndex] as HTMLElement, metadata, options);
                return;
            }
        }

        if (NAVIGATION_KEYS.includes(key)) {
            // @ts-expect-error null-check
            if ([KEYBOARD_KEYS.END, KEYBOARD_KEYS.HOME].includes(key) && !isVisibleElement(optionsContainer)) {
                showOptionsContainer(metadata);
            }

            event.preventDefault();
            visualFocusIndex = getVisualFocusIndex(key, visualFocusIndex, options.length - 1);
            scrollToOption(options, visualFocusIndex, metadata);

            return;
        }

        if (isKeySearchApplicable(key)) {
            const searchResultIndex = getSearchResultIndex(key);

            if (searchResultIndex === undefined) {
                return;
            }

            // @ts-expect-error null-check
            if (!isVisibleElement(optionsContainer)) {
                showOptionsContainer(metadata);
            }

            visualFocusIndex = searchResultIndex;
            scrollToOption(options, visualFocusIndex, metadata);
        }
    };
};

const isKeySearchApplicable = (key: string): boolean => {
    return LETTER_KEY_REGEXP.test(key);
};

const makeSearchHandler = (options: NodeListOf<Element>): ((key: string) => number) => {
    let searchQuery = '';
    let searchTimeout: ReturnType<typeof setTimeout>;

    return (key: string): number => {
        searchQuery += key.toLowerCase();

        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(() => (searchQuery = ''), 500);

        const selectedOption = Array.from(options).find((option) => option?.textContent?.trim()?.toLowerCase()?.startsWith(searchQuery));

        if (!selectedOption) {
            // @ts-expect-error null-check
            return;
        }

        return Array.from(options).indexOf(selectedOption);
    };
};
