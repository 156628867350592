import { getContext } from '../context';
import { getDeprecatedFormIdSelector, getFormIDSelector } from '../elements';

export const omitEmptyValues = (obj: Record<string, unknown>): Record<string, unknown> => {
    return Object.keys(obj)
        .filter((key) => obj[key] !== null && typeof obj[key] !== 'undefined' && obj[key] !== '')
        .reduce((result, key) => ({ ...result, [key]: obj[key] }), {});
};

export const getHoursTimestamp = (): string => {
    return new Date().toISOString().slice(0, 13);
};

export const safeExecute = (action: () => void): void => {
    try {
        action();
    } catch (error) {
        /* eslint-disable no-console */
        console.error(error);
    }
};

export const markFormClosed = (formID: string): void => {
    getContext().cookies.set(`${getFormIDSelector(formID)}-closed-at`, new Date().toISOString());
};

export const markFormFilled = (formID: string): void => {
    getContext().cookies.set(`${getFormIDSelector(formID)}-filled-at`, new Date().toISOString());
};

export const markFormTeaserClosed = (formID: string): void => {
    getContext().cookies.set(`${getFormIDSelector(formID)}-teaser-closed-at`, new Date().toISOString());
};

export const markFormVersionSelected = (formID: string): void => {
    getContext().cookies.set(`${getFormIDSelector(formID)}-version-selected`, new Date().toISOString());
};

export const getFormFilledCookie = (formID: string): string => {
    // @ts-expect-error null-check
    return (
        getContext().cookies.get(`${getFormIDSelector(formID)}-filled-at`) ||
        getContext().cookies.get(`${getDeprecatedFormIdSelector(formID)}-filled-at`)
    );
};

export const getFormClosedCookie = (formID: string): string => {
    // @ts-expect-error null-check
    return (
        getContext().cookies.get(`${getFormIDSelector(formID)}-closed-at`) ||
        getContext().cookies.get(`${getDeprecatedFormIdSelector(formID)}-closed-at`)
    );
};

export const getFormTeaserClosedCookie = (formID: string): string =>
    // @ts-expect-error null-check
    getContext().cookies.get(`${getFormIDSelector(formID)}-teaser-closed-at`);

export const getFormVersionSelectedCookie = (formID: string): string => {
    // @ts-expect-error null-check
    return getContext().cookies.get(`${getFormIDSelector(formID)}-version-selected`);
};
