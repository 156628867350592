import { getContext } from '../../../context';
import { DeviceType } from '../../../types';

export type TrackingResults = {
    triedToClose: boolean;
    loadTime: number;
    pageViewCount: number;
    scrolledPercent: number;
    isClosedFormInSession: (formId: string) => boolean;
    isClosedFormTeaserInSession: (formId: string) => boolean;
};

export type ChangeHandler = (results: TrackingResults) => void;

const results: TrackingResults = {
    triedToClose: false,
    loadTime: Date.now(),
    pageViewCount: 0,
    scrolledPercent: determineScrollPercentage(),
    isClosedFormInSession: (formId: string) => sessionClosedFormIds.includes(formId),
    isClosedFormTeaserInSession: (formId: string) => sessionClosedFormTeaserIds.includes(formId)
};

const changeHandlers: ChangeHandler[] = [];
const sessionClosedFormIds: string[] = [];
const sessionClosedFormTeaserIds: string[] = [];

function determineScrollPercentage(): number {
    return Math.round((window.scrollY * 100) / (document.documentElement.scrollHeight - document.documentElement.clientHeight)) || 0;
}

function trackClose(): void {
    const SCROLL_DELTA_THRESHOLD = -200;
    const SCROLL_TRACK_DURATION = 100;

    const isMobile = getContext().user.getDeviceType() === DeviceType.mobile;

    if (!isMobile) {
        window.addEventListener('mouseout', (event) => {
            if (event.y < 0) {
                results.triedToClose = true;
                changeHandlers.forEach((handler) => handler(results));
            }
        });

        return;
    }

    let timer: NodeJS.Timeout;

    const trackMobileScroll = (): void => {
        if (timer) {
            return;
        }

        const currentScrollPosition = window.scrollY;

        timer = setTimeout(() => {
            const newPosition = window.scrollY;
            const scrollDelta = newPosition - currentScrollPosition;

            if (scrollDelta <= SCROLL_DELTA_THRESHOLD) {
                results.triedToClose = true;
                changeHandlers.forEach((handler) => handler(results));
                window.removeEventListener('scroll', trackMobileScroll);
            }

            clearTimeout(timer);
            // @ts-expect-error null-check
            timer = null;
        }, SCROLL_TRACK_DURATION);
    };
    window.addEventListener('scroll', trackMobileScroll);
}

function trackScrolling(): void {
    window.addEventListener('scroll', () => {
        const percent = determineScrollPercentage();

        if (percent <= results.scrolledPercent) return;

        results.scrolledPercent = percent;

        changeHandlers.forEach((handler) => handler(results));
    });
}

function trackPageViews(): void {
    const { cookies } = getContext();
    // @ts-expect-error null-check
    const existingViews = parseInt(cookies.get('page-views'), 10) || 0;
    const newViews = existingViews + 1;

    cookies.set('page-views', newViews.toString(), { sessionOnly: true });

    results.pageViewCount = newViews;
}

export function onActivityChange(handler: ChangeHandler): void {
    changeHandlers.push(handler);
}

// TODO: improve performance by enabling trackers only when needed
export function monitorActivity(): void {
    trackClose();
    trackScrolling();
    trackPageViews();
}

export const setFormClosedInSession = (formId: string): void => {
    if (sessionClosedFormIds.includes(formId)) {
        return;
    }
    sessionClosedFormIds.push(formId);
};

export const setFormTeaserClosedInSession = (formId: string): void => {
    if (sessionClosedFormTeaserIds.includes(formId)) {
        return;
    }
    sessionClosedFormTeaserIds.push(formId);
};

export function getActivityInformation(): TrackingResults {
    return results;
}
