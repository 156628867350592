import { getFocusableElements, getFormContentInnerSelector } from '../elements';
import { FocusLocker, FormData } from '../types';
import { isVisibleElement } from '../utils';
import { createFocusTrap } from './focus-trap';
import { getFirst, getLast } from './utils';

function handleBoundaries({ elementList, navigatingBack }: { elementList: HTMLElement[]; navigatingBack: boolean }): boolean {
    const lastElement = getLast(elementList);
    const firstElement = getFirst(elementList);

    const boundaryElement = navigatingBack ? firstElement : lastElement;
    const elementToFocus = navigatingBack ? lastElement : firstElement;

    const isInScope = elementList.some((element) => document.activeElement === element);

    if (isInScope && document.activeElement !== boundaryElement) {
        return false;
    }

    elementToFocus?.focus();

    return true;
}

type FocusLockHandle = ReturnType<typeof handleFocusLock>;

function handleFocusLock(element: HTMLElement, formData: FormData): () => void {
    const focusableElements = getFocusableElements(element).filter(isVisibleElement);

    const handler = (e: KeyboardEvent): void => {
        const isTabPressed = e.key === 'Tab';

        if (!isTabPressed) return;
        if (!focusableElements.length) {
            e.preventDefault();
            return;
        }

        handleBoundaries({ elementList: focusableElements, navigatingBack: e.shiftKey }) && e.preventDefault();
    };

    document.addEventListener('keydown', handler);

    const resetKeydownHandler = (): void => {
        document.removeEventListener('keydown', handler);
    };

    const destroyFocusTrap = createFocusTrap({ element, focusableElements, formData, cleanupFocusTrapInitiator: resetKeydownHandler });

    return () => {
        resetKeydownHandler();
        destroyFocusTrap();
    };
}

let locker: FocusLocker;

export const getFocusLocker = (): FocusLocker => {
    return locker;
};

export function createFocusLocker(): void {
    let cleanupFocusLock: FocusLockHandle | null = null;

    const clear = (): void => {
        if (!cleanupFocusLock) return;

        cleanupFocusLock();
        cleanupFocusLock = null;
    };

    locker = {
        lock: (element: HTMLElement, formData: FormData) => {
            clear();
            cleanupFocusLock = handleFocusLock(element, formData);
        },
        clear
    };
}

export const focusFormContent = (formID: string): void => {
    // @ts-expect-error null-check
    const formContent: HTMLElement = document.querySelector(getFormContentInnerSelector(formID));

    formContent?.focus({ preventScroll: true });
};
