import { getContext, getOmnisendContext } from './context';
import { Form, SubmitBackInStockPayload, SubscribeFormData, SubscribePayload, SubscribeResult, WofSpinResult } from './types';
import { CONTACT_ID_COOKIE } from './constants';

export const subscribeForm = async (form: Form, formData: SubscribeFormData): Promise<SubscribeResult> => {
    const { brand, navigation, forms, cookies } = getContext();
    const { contactID, unconfirmedContactID } = form.getContactIdentifier();

    const payload: SubscribePayload = {
        // @ts-expect-error null-check
        brandID: brand.getBrandID(),
        formID: form.data.id,
        stepID: form.getCurrentStepID(),
        pageData: {
            URL: navigation.getPageUrl(),
            title: navigation.getPageTitle(),
            timeToSubmit: form.timeFormInteracted ? Date.now() - form.timeFormInteracted : undefined
        },
        formData,
        contactID,
        cookieContactID: cookies.get(CONTACT_ID_COOKIE),
        unconfirmedContactID,
        correlationID: form.getCorrelationID()
    };

    const response = await (
        await fetch(forms.getFormsSubscribeEndpoint(), {
            method: 'POST',
            body: JSON.stringify(payload)
        })
    ).json();

    if (response.error) {
        throw response;
    }

    return response;
};

export const submitBackInStock = async (form: Form, formData: SubscribeFormData): Promise<SubscribeResult> => {
    const { brand, navigation, forms, cookies } = getContext();
    const { contactID, unconfirmedContactID } = form.getContactIdentifier();
    const productInfo = getOmnisendContext().getProductInfo();

    const payload: SubmitBackInStockPayload = {
        // @ts-expect-error null-check
        productID: productInfo?.productID,
        // @ts-expect-error null-check
        variantID: productInfo?.variantID,
        // @ts-expect-error null-check
        brandID: brand.getBrandID(),
        formID: form.data.id,
        stepID: form.getCurrentStepID(),
        pageData: {
            URL: navigation.getPageUrl(),
            title: navigation.getPageTitle(),
            timeToSubmit: form.timeFormInteracted ? Date.now() - form.timeFormInteracted : undefined
        },
        formData,
        contactID,
        cookieContactID: cookies.get(CONTACT_ID_COOKIE),
        unconfirmedContactID
    };

    const response = await (
        await fetch(forms.getFormsBackInStockEndpoint(), {
            method: 'POST',
            body: JSON.stringify(payload)
        })
    ).json();

    if (response.error) {
        throw response;
    }

    return response;
};

export const spinWof = async (form: Form): Promise<WofSpinResult> => {
    const { brand, forms } = getContext();

    const payload: { brandID: string; formID: string; stepID: string } = {
        // @ts-expect-error null-check
        brandID: brand.getBrandID(),
        formID: form.data.id,
        stepID: form.getCurrentStepID()
    };

    const response = await (
        await fetch(forms.getWofSpinEndpoint(), {
            method: 'POST',
            body: JSON.stringify(payload)
        })
    ).json();

    if (response.error) {
        throw response;
    }

    return response;
};
