import { CONTAINER_WIDTH } from './constants';

const REGEXP = /(.+)(\/forms\/.+)/;
const IMAGE_DPI_MULTIPLIER = 2;

const getImageHostAndRelativeUrl = (url: string): { hostUrl: string; relativeUrl: string } => {
    const match = REGEXP.exec(url);
    // @ts-expect-error null-check
    return { hostUrl: match[1], relativeUrl: match[2] };
};

export const updateImageUrlWithQueryParams = ({ imageUrl, highDpi }: { imageUrl: string; highDpi: boolean }): string => {
    const { hostUrl, relativeUrl } = getImageHostAndRelativeUrl(imageUrl);
    const imageWidth = highDpi ? CONTAINER_WIDTH * IMAGE_DPI_MULTIPLIER : CONTAINER_WIDTH;

    return `${hostUrl}/cdn-cgi/image/fit=scale-down,width=${Math.ceil(imageWidth)}${relativeUrl}`;
};
