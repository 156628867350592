import { attachSelectListeners } from '../components/select/select';
import { PHONE_NUMBER_FIELD_NAME } from '../constants';
import {
    getFormFieldSelectOptionsContainerSelector,
    getFormFieldSelectOptionSelector,
    getFormFieldSelectOptionValueSelector,
    getFormFieldSelectPlaceholderSelector,
    getFormFieldSelectSelector,
    getFormFieldSelectTextSelector,
    getPhoneNumberPrefixOptionFlagSelector,
    getPhoneNumberPrefixOptionsContainerSelector,
    getPhoneNumberPrefixOptionSelector,
    getPhoneNumberPrefixOptionValueSelector,
    getPhoneNumberPrefixSelector,
    getPhoneNumberPrefixValueSelector
} from '../elements';
import { Form } from '../types';
import { isFieldTypeSelect } from '../utils';

export const attachSelectFieldsListeners = (formElement: HTMLElement, { data }: Form): void => {
    const { id, fields } = data;

    // @ts-expect-error null-check
    fields
        .filter((field) => isFieldTypeSelect(formElement, id, field))
        .forEach(({ targetID, name }) => {
            attachSelectListeners({
                formElement,
                displayType: data.displayType,
                selectors: {
                    select: getFormFieldSelectSelector(id, targetID, name),
                    selectText: getFormFieldSelectTextSelector(id, targetID),
                    selectPlaceholder: getFormFieldSelectPlaceholderSelector(id, targetID),
                    optionsContainer: getFormFieldSelectOptionsContainerSelector(id, targetID),
                    option: getFormFieldSelectOptionSelector(id, targetID),
                    optionValue: getFormFieldSelectOptionValueSelector(id, targetID)
                }
            });
        });

    // @ts-expect-error null-check
    const phoneNumberField = fields.find((field) => field.name === PHONE_NUMBER_FIELD_NAME);

    if (phoneNumberField) {
        const selectSelector = getPhoneNumberPrefixSelector(id, phoneNumberField.targetID);
        attachSelectListeners({
            formElement,
            displayType: data.displayType,
            selectors: {
                select: selectSelector,
                selectText: getPhoneNumberPrefixValueSelector(id, phoneNumberField.targetID),
                optionsContainer: getPhoneNumberPrefixOptionsContainerSelector(id, phoneNumberField.targetID),
                option: getPhoneNumberPrefixOptionSelector(id, phoneNumberField.targetID),
                optionValue: getPhoneNumberPrefixOptionValueSelector(id, phoneNumberField.targetID)
            },
            additionalOptionSelectCallback: (selectedOptionElement: Element) => {
                const flagClass = selectedOptionElement.getAttribute('data-flag-class');
                // @ts-expect-error null-check
                const phonePrefixFlagElement = formElement
                    .querySelector(selectSelector)
                    .querySelector(getPhoneNumberPrefixOptionFlagSelector(id, phoneNumberField.targetID));
                // @ts-expect-error null-check
                const previousFlagClass = phonePrefixFlagElement.classList[1];
                // @ts-expect-error null-check
                phonePrefixFlagElement.classList.remove(previousFlagClass);
                // @ts-expect-error null-check
                phonePrefixFlagElement.classList.add(flagClass);
            }
        });
    }
};
