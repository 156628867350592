import { CENTER_X, CENTER_Y, RADIUS, SVG_NAMESPACE_URI, DISTANCE, CONTAINER_WIDTH, CONTAINER_HEIGHT } from './constants';
import { updateImageUrlWithQueryParams } from './update-image-url';

type Slice = {
    startAngle: number;
    sliceAngle: number;
    sliceID: string;
    textColor?: string;
    text?: string;
    imageUrl?: string;
    altText?: string;
};

const DEFAULT_ALT_TEXT = 'slice image';

const getImageHeight = ({ sliceAngle }: Pick<Slice, 'sliceAngle'>): string => {
    const sliceAngleInRadians = (sliceAngle * Math.PI) / 180;
    const sliceHeight = RADIUS * 2 * Math.sin(sliceAngleInRadians / 4);
    return Math.floor(sliceHeight) + 'px';
};

const setDefaultWrapperStyles = (element: HTMLDivElement): void => {
    element.style.display = 'flex';
    element.style.alignItems = 'center';
    element.style.justifyContent = 'flex-end';
    element.style.height = '100%';
};

const createSVGContainer = ({ startAngle, sliceAngle }: Pick<Slice, 'startAngle' | 'sliceAngle'>): SVGForeignObjectElement => {
    const angle = startAngle + sliceAngle / 2;
    const positionX = CENTER_X + DISTANCE * Math.cos((angle * Math.PI) / 180);
    const positionY = CENTER_Y + DISTANCE * Math.sin((angle * Math.PI) / 180);

    const foreignObject = document.createElementNS(SVG_NAMESPACE_URI, 'foreignObject');
    foreignObject.setAttribute('x', (positionX - CONTAINER_WIDTH / 2).toString()); // Center the text horizontally
    foreignObject.setAttribute('y', (positionY - CONTAINER_HEIGHT / 2).toString()); // Adjust for vertical centering
    foreignObject.setAttribute('width', CONTAINER_WIDTH.toString()); // Fixed width for text wrapper
    foreignObject.setAttribute('height', CONTAINER_HEIGHT.toString()); // Enough height for wrapped text
    foreignObject.setAttribute('transform', `rotate(${angle}, ${positionX}, ${positionY})`);

    return foreignObject;
};

const createContentWrapper = ({ sliceID }: Pick<Slice, 'sliceID'>): HTMLDivElement => {
    const wrapperEl = document.createElement('div');
    setDefaultWrapperStyles(wrapperEl);
    wrapperEl.setAttribute('id', sliceID);
    wrapperEl.setAttribute('data-slice', 'true');

    return wrapperEl;
};

const createSliceLabel = ({
    startAngle,
    sliceAngle,
    textColor,
    text,
    sliceID
}: Omit<Slice, 'imageUrl' | 'altText'>): SVGForeignObjectElement => {
    const foreignObject = createSVGContainer({ startAngle, sliceAngle });
    const contentWrapper = createContentWrapper({ sliceID });

    // @ts-expect-error null-check
    contentWrapper.style.color = textColor;
    // @ts-expect-error null-check
    contentWrapper.textContent = text;

    foreignObject.appendChild(contentWrapper);

    return foreignObject;
};

const createSliceImage = ({
    startAngle,
    sliceAngle,
    sliceID,
    imageUrl,
    altText
}: Omit<Slice, 'text' | 'textColor'>): SVGForeignObjectElement => {
    const foreignObject = createSVGContainer({ startAngle, sliceAngle });
    const contentWrapper = createContentWrapper({ sliceID });

    const imageElement = document.createElement('img');
    // @ts-expect-error null-check
    imageElement.src = updateImageUrlWithQueryParams({ imageUrl: imageUrl, highDpi: false });
    // @ts-expect-error null-check
    imageElement.srcset = updateImageUrlWithQueryParams({ imageUrl: imageUrl, highDpi: true }) + ' 2x';
    imageElement.alt = altText || DEFAULT_ALT_TEXT;
    imageElement.style.objectFit = 'contain';
    imageElement.style.maxWidth = '100%';
    imageElement.style.maxHeight = '100%';
    imageElement.style.height = getImageHeight({ sliceAngle });

    contentWrapper.appendChild(imageElement);
    foreignObject.appendChild(contentWrapper);

    return foreignObject;
};

export const createSliceContent = ({
    startAngle,
    sliceAngle,
    sliceID,
    text,
    textColor,
    imageUrl,
    altText
}: Slice): SVGForeignObjectElement => {
    if (imageUrl) {
        return createSliceImage({ startAngle, sliceAngle, sliceID, imageUrl, altText });
    }

    return createSliceLabel({ startAngle, sliceAngle, sliceID, text, textColor });
};

export const createSliceElement = ({
    startAngle,
    endAngle,
    sliceAngle,
    backgroundColor
}: {
    startAngle: number;
    endAngle: number;
    sliceAngle: number;
    backgroundColor: string;
}): SVGPathElement => {
    const startX = CENTER_X + RADIUS * Math.cos((startAngle * Math.PI) / 180);
    const startY = CENTER_Y + RADIUS * Math.sin((startAngle * Math.PI) / 180);
    const endX = CENTER_X + RADIUS * Math.cos((endAngle * Math.PI) / 180);
    const endY = CENTER_Y + RADIUS * Math.sin((endAngle * Math.PI) / 180);

    const sliceElement = document.createElementNS(SVG_NAMESPACE_URI, 'path');
    const largeArcFlag = sliceAngle > 180 ? 1 : 0;

    sliceElement.setAttribute(
        'd',
        `
    M ${CENTER_X},${CENTER_Y}
    L ${startX},${startY}
    A ${RADIUS},${RADIUS} 0 ${largeArcFlag} 1 ${endX},${endY}
    Z
  `
    );
    sliceElement.setAttribute('fill', backgroundColor);
    return sliceElement;
};
